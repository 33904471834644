import React from 'react';

import emailHandler, { templateIds } from '@utils/emailHandler';
import Contact from './Contact';

function ContactContainer(props) {
  const { send: sendResponse } = emailHandler({ templateId: templateIds.HOMEPAGE_CONTACT_AUTO_RESPONSE });
  const { send: sendStaff } = emailHandler({ templateId: templateIds.HOMEPAGE_CONTACT_STAFF });

  const handleSubmit = async (values) => {
    await Promise.all([
      sendResponse({
        sender: 'info@misshalls.org',
        recipient: values.email,
        variables: values,
      }),
      sendStaff({
        sender: 'info@misshalls.org',
        recipient: 'info@misshalls.org',
        variables: values,
      }),
    ]);
  };

  return (
    <Contact onSubmit={handleSubmit} {...props} />
  );
}

export default ContactContainer;
