import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { useIntl } from '@utils/localize';
import get from 'lodash/get';

import Button from '@components/Button';
import Image from '@components/Image';
import { Body, Heading } from '@components/type';
import { RoughTwo, RoughThree } from '@components/vector';
import * as styled from './styles';

function Contact({ description, heading, onSubmit, person, isHidden }) {
  const intl = useIntl();

  const [interests, setInterests] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const { control, errors, formState, handleSubmit, register, reset } = useForm({
    mode: 'onChange',
  });

  const _handleSubmit = async (values) => {
    await onSubmit({ ...values, interests: interests.join(', ') });
    setSubmitted(true);
  };

  const handleClose = () => {
    reset();
    setInterests([]);
    setSubmitted(false);
  };

  const handleCheck = (name) => {
    setInterests(
      interests.includes(name)
        ? interests.filter((n) => n !== name)
        : [...interests, name],
    );
  };

  return (
    <styled.Wrapper>
      <styled.Inner>
        <styled.Info>
          <styled.Heading level={100}>{heading}</styled.Heading>
          <Body sizes={['small', 'regular']}>{description}</Body>
        </styled.Info>
        <styled.FormWrapper>
          {get(person, 'name') && (
            <styled.Person>
              {get(person, 'image.asset.fluid') && (
                <styled.PersonImageWrapper>
                  <Image edge={0} fluid={person.image.asset.fluid} />
                </styled.PersonImageWrapper>
              )}
              <styled.PersonInfo>
                <Heading levels={[500, 300]}>{person.name}</Heading>
                {person.title && (
                  <Body sizes={['small', 'regular']}>{person.title}</Body>
                )}
              </styled.PersonInfo>
            </styled.Person>
          )}
          <RoughThree />
          <styled.FormBackground>
            <styled.FormInner hasPerson={get(person, 'name')}>
              <styled.Success
                animate={submitted ? 'center' : 'enter'}
                initial="enter"
                variants={{
                  enter: {
                    opacity: 0,
                    x: '100%',
                  },
                  center: {
                    opacity: 1,
                    x: '0%',
                  },
                }}
              >
                <styled.Heading levels={[400, 200]}>
                  {intl.formatMessage({ id: 'contact.form.success.header' })}
                </styled.Heading>
                <Body>{intl.formatMessage({ id: 'contact.form.success.subtext' })}</Body>
                <Button appearance="blue" fill onClick={handleClose}>
                  {intl.formatMessage({ id: 'contact.form.success.bittpm' })}
                </Button>
              </styled.Success>
              <motion.form
                animate={submitted ? 'exit' : 'center'}
                initial="center"
                variants={{
                  center: {
                    opacity: 1,
                    x: '0%',
                  },
                  exit: {
                    opacity: 0,
                    x: '-100%',
                  },
                }}
                onSubmit={handleSubmit(_handleSubmit)}
              >
                <styled.Field>
                  <styled.Label
                    error={get(formState, 'touched.email') && errors.email}
                  >
                    {intl.formatMessage({ id: 'contact.form.label.email' })}*
                    <styled.Input
                      error={get(formState, 'touched.email') && errors.email}
                      name="email"
                      type="email"
                      ref={register({
                        required: true,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      })}
                    />
                  </styled.Label>
                  {get(formState, 'touched.email') && errors.email && (
                    <styled.FormError>
                      {intl.formatMessage({ id: 'contact.form.error.email' })}
                    </styled.FormError>
                  )}
                </styled.Field>
                <styled.Field>
                  <styled.Label>
                    {intl.formatMessage({ id: 'contact.form.label.name' })}
                    <styled.Input name="name" ref={register} />
                  </styled.Label>
                </styled.Field>
                <styled.Field>
                  <styled.Label>
                    {intl.formatMessage({ id: 'contact.form.label.phone' })}
                    <Controller
                      as={styled.Input}
                      control={control}
                      name="phone"
                      type="tel"
                    />
                  </styled.Label>
                </styled.Field>
                <styled.Field>
                  <styled.Label>{intl.formatMessage({ id: 'contact.form.label.interest' })}</styled.Label>
                  <styled.Checkboxes>
                    <styled.Checkbox
                      checked={interests.includes('Applying')}
                      onClick={() => {
                        handleCheck('Applying');
                      }}
                    >
                      {intl.formatMessage({ id: 'contact.form.field.interest.applying' })}
                    </styled.Checkbox>
                    <styled.Checkbox
                      checked={interests.includes('Visiting')}
                      onClick={() => {
                        handleCheck('Visiting');
                      }}
                    >
                      {intl.formatMessage({ id: 'contact.form.field.interest.visiting' })}
                    </styled.Checkbox>
                    <styled.Checkbox
                      checked={interests.includes('Information')}
                      onClick={() => {
                        handleCheck('Information');
                      }}
                    >
                      {intl.formatMessage({ id: 'contact.form.field.interest.information' })}
                    </styled.Checkbox>
                  </styled.Checkboxes>
                </styled.Field>
                <styled.Field>
                  <styled.Label>
                    {intl.formatMessage({ id: 'contact.form.label.message' })}
                    <styled.Textarea name="message" ref={register} />
                  </styled.Label>
                </styled.Field>
                <Button
                  appearance="blue"
                  isDisabled={!get(formState, 'isValid')}
                  fill
                  type="submit"
                >
                  {intl.formatMessage({ id: 'contact.form.submit' })}
                </Button>
              </motion.form>
            </styled.FormInner>
          </styled.FormBackground>
          <RoughTwo />
        </styled.FormWrapper>
      </styled.Inner>
    </styled.Wrapper>
  );
}

Contact.propTypes = {
  description: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  person: PropTypes.shape({
    image: PropTypes.object,
    name: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default Contact;
